export const LINKS = {
  home: "/",
  customers: "/customers",
  authLogin: "/auth/login",
  authVerifyRequest: "/auth/verify-request",
  customerDetail: (id: string) => `/customer/${id}`,
  operatorDashboard: "/operator-dashboard",
  unauthorized: "/unauthorized",
  authMagicCallback: "/auth/magic/callback",
  authMagicLinkInvalid: (reason?: "invalidToken" | "tokenExpired") =>
    `/auth/magic-link-invalid${reason ? `?reason=${reason}` : ""}`,
}

export const API_ROUTES = {
  authMagicLogin: "/api/auth/magic/login",
  authMagicCallback: "/api/auth/magic/callback",
  authLogout: "/api/auth/logout",
  authImpersonate: "/api/auth/impersonate",
  authLoginDev: "/api/dev/login",
}
