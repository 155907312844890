"use client"

import * as LabelPrimitive from "@radix-ui/react-label"
import { IconInfoCircle } from "@tabler/icons-react"
import * as React from "react"
import { tv, VariantProps } from "tailwind-variants"

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/Tooltip"
import { cn } from "@/utils/tailwind"

const labelVariants = tv({
  base: "relative flex text-sm font-bold leading-none text-sherpa-blue-950 peer-disabled:cursor-not-allowed peer-disabled:opacity-70",
})

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> &
    VariantProps<typeof labelVariants> & { info?: string; required?: boolean }
>(({ className, info, required, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props}>
    {props.children}
    {required && <span className="text-red-500">*</span>}
    {info && (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger type="button" className="relative top-0 -mt-3 ml-1 text-gray-400">
            <span>
              <IconInfoCircle size={14} className="text-nano-gray-900" />
            </span>
          </TooltipTrigger>
          <TooltipContent className="w-96">
            <p className="font-light">{info}</p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )}
  </LabelPrimitive.Root>
))
Label.displayName = LabelPrimitive.Root.displayName

export { Label }
