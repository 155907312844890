import { forwardRef } from "react"

import useDisableNumberInputScroll from "@/hooks/useDisableNumberInputScroll"
import { cn } from "@/utils/tailwind"

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  const internalRef = useDisableNumberInputScroll(ref)

  return (
    <input
      type={type}
      className={cn(
        "flex h-14 w-full rounded-md border border-slate-200 bg-white px-4 py-2 text-sm text-sherpa-blue-950 ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-sm placeholder:text-slate-400 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300",
        className,
      )}
      ref={internalRef}
      {...props}
    />
  )
})
Input.displayName = "Input"

export { Input }
