import { isNotNil } from "@deltagreen/utils"
import { useEffect, useImperativeHandle, useRef } from "react"

const useDisableNumberInputScroll = (ref: React.ForwardedRef<HTMLInputElement>) => {
  const internalRef = useRef<HTMLInputElement | null>(null)

  useImperativeHandle(ref, () => internalRef.current as HTMLInputElement, [internalRef])

  useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      e.preventDefault()
    }

    const input = internalRef.current
    if (isNotNil(input) && input.type === "number") {
      input.addEventListener("wheel", handleWheel, { passive: false })
    }

    return () => {
      if (isNotNil(input) && input.type === "number") {
        input.removeEventListener("wheel", handleWheel)
      }
    }
  }, [internalRef])

  return internalRef
}

export default useDisableNumberInputScroll
